// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_tZ d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_t0 d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_t1 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_t2 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_t3 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_t4 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_t5 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_r9 q_r9";
export var heroExceptionNormal = "v_sb q_sb";
export var heroExceptionLarge = "v_sc q_sc";
export var Title1Small = "v_rR q_rR q_rl q_rm";
export var Title1Normal = "v_rS q_rS q_rl q_rn";
export var Title1Large = "v_rT q_rT q_rl q_rp";
export var BodySmall = "v_r4 q_r4 q_rl q_rF";
export var BodyNormal = "v_r5 q_r5 q_rl q_rG";
export var BodyLarge = "v_r6 q_r6 q_rl q_rH";